<template>
  <el-dialog
    title="厚度编辑"
    :visible.sync="dialogVisible"
    width="800px"
    :close-on-click-modal="false"
    @close="onDialogClose"
    append-to-body
  >
    <el-form
      :model="form"
      ref="form"
      label-width="100px"
      class="multiple-ruleForm"
      label-position="top"
      size="small"
      :disabled="submitting"
    >
      <el-row :gutter="10">
        <el-col
          :sm="24"
          :md="24"
          v-for="(item, index) in form.items"
          :key="item.key"
        >
          <el-card
            class="box-card"
            shadow="never"
            :body-style="{
              padding: ' 0 10px',
            }"
          >
            <div slot="header" class="clearfix">
              条目{{ index + 1 }}
              <el-button
                class="text-danger"
                style="float: right; padding: 3px 0"
                type="text"
                @click="onDeleteRow(index)"
                :disabled="submitting || form.items.length <= 1"
                >删除</el-button
              >
            </div>
            <el-row :gutter="10">
              <el-col :sm="24" :md="6">
                <el-form-item
                  label-position="left"
                  label="尺寸"
                  :prop="'items.' + index + '.lengthWidthId'"
                  :rules="[
                    {
                      required: true,
                      message: '请选择尺寸',
                      trigger: 'change',
                    },
                  ]"
                >
                  <el-select
                    v-model="item.lengthWidthId"
                    placeholder="请选择长宽"
                    class="form-select"
                    @change="handleExistCheck(item, index)"
                  >
                    <el-option
                      v-for="item in lengthWidthsOptions"
                      :key="item.id"
                      :label="item.description"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :sm="24" :md="6">
                <el-form-item
                  label-position="left"
                  label="厚度"
                  :prop="'items.' + index + '.thicknessId'"
                  :rules="[
                    {
                      required: true,
                      message: '请选择厚度',
                      trigger: 'change',
                    },
                  ]"
                >
                  <el-select
                    v-model="item.thicknessId"
                    placeholder="请选择厚度"
                    class="form-select"
                    @change="handleExistCheck(item, index)"
                  >
                    <el-option
                      v-for="item in thicknessesOptions"
                      :key="item.id"
                      :label="item.name.i18n"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :sm="24" :md="6">
                <el-form-item
                  label="次序(数值越大越靠前)"
                  :prop="'items.' + index + '.seq'"
                  :rules="[
                    { required: true, message: '请输入次序', trigger: 'blur' },
                  ]"
                >
                  <el-input
                    :disabled="submitting"
                    v-model.trim="item.seq"
                    clearable
                    :ref="`input${index}`"
                    placeholder="请输入"
                    type="number"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :sm="24" :md="6">
                <el-form-item
                  label="是否已存在"
                  :prop="'items.' + index + '.isExists'"
                >
                  <div v-if="item.isChecking">
                    <i class="el-icon-loading"></i>
                    查重中...
                  </div>
                  <div v-else>
                    <span class="text-danger" v-if="item.isExists"
                      >该组合已存在</span
                    >
                    <span class="text-success" v-else>可添加</span>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-card></el-col
        >
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleReset" :disabled="submitting">重 置</el-button>
      <el-button @click="addRow" :disabled="submitting">新增一行</el-button>
      <el-button
        type="primary"
        :loading="submitting"
        @click="submitForm('form')"
        >{{ submitting ? "提交中..." : "提交创建" }}</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { createUniqueString } from "@/utils/common";
import {
  PostProductSpecifications,
  GetProductSpecificationsCheck,
} from "../api";
import { ShowApiError } from "@/request/error";
import options from "../mixins/options";
import { isEmprty } from "@/utils/validate";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  mixins:[options],
  data() {
    return {
      submitting: false,
      focusIndex: 0,
      dialogVisible: false,
      form: {
        items: [],
      },
    };
  },

  watch: {
    show: {
      handler(value) {
        this.dialogVisible = value;
        if (this.dialogVisible) {
          this.initFormOptions();
        }
      },
      immediate: true,
    },
  },
  created() {
    this.initForm();
  },
  methods: {
    createUniqueString,
    async handleExistCheck(item, index) {
      if (isEmprty(item.lengthWidthId) || isEmprty(item.thicknessId)) {
        this.form.items[index].isExists = false;
        return;
      }
      try {
        item.isChecking = true;
        let { data } = await GetProductSpecificationsCheck({
          lengthWidthId: item.lengthWidthId,
          thicknessId: item.thicknessId,
        });
        this.form.items[index].isExists = data;
      } finally {
        item.isChecking = false;
      }
    },

    buildFormData() {
      let datas = [];
      datas = this.form.items
        .filter((item) => item.isExists == false)
        .map((item) => {
          delete item.key;
          delete item.isExists;
          delete item.isChecking;
          return item;
        });
      return datas;
    },

    validForm() {
      return new Promise((resolve, reject) => {
        let res = this.form.items.every((item) => item.isExists == false);
        if (res) {
          resolve();
        } else {
          this.$confirm(
            "提交的表单中存在重复的组合，是否需要自动去除并提交?",
            "重复提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          )
            .then(() => {
              resolve();
              this.$message.success("表单已重置");
            })
            .catch(() => {
              this.$message.info("已取消");
              reject();
            });
        }
      });
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.validForm().then(() => {
            this.submitting = true;
            PostProductSpecifications(this.buildFormData())
              .then(() => {
                this.submitting = false;
                this.$message.success("新增成功");
                this.$emit("success");
                this.initForm();
              })
              .catch((err) => {
                this.submitting = false;
                ShowApiError("新增失败", err);
              });
          });
        } else {
          this.$message.warning("表单有误，请检查");
          return false;
        }
      });
    },
    initForm() {
      this.form = {
        items: [
          {
            lengthWidthId: null,
            thicknessId: null,
            isExists: false,
            isChecking: false,
            seq: 1,
            key: this.createUniqueString(),
          },
        ],
      };
    },
    addRow(inputObj = "") {
      this.form.items.push({
        lengthWidthId: null,
        thicknessId: null,
        isExists: false,
        isChecking: false,
        seq: this.form.items.length + 1,
        key: this.createUniqueString(),
      });
      this.$nextTick(() => {
        if (this.$refs[inputObj]) {
          this.$refs[inputObj][0].focus();
        }
      });
    },
    onDeleteRow(index) {
      if (this.form.items.length > 1) {
        this.form.items.splice(index, 1);
      }
    },
    onDialogClose() {
      this.dialogVisible = false;
      this.$emit("close", this.dialogVisible);
    },
    handleReset() {
      this.$confirm("操作不可撤回，确定要重置表单吗?", "重置提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.initForm();
          this.$message.success("表单已重置");
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.form-select {
  width: 100%;
}
.icon-img {
  width: 100px;
  height: 100px;
}
.form-tips {
  font-size: 13px;
  text-align: left;
  padding: 10px;
}
.box-card {
  margin-bottom: 10px;
}
.multiple-ruleForm {
  padding: 0 10px;
}
</style>
