var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"title":"厚度编辑","visible":_vm.dialogVisible,"width":"800px","close-on-click-modal":false,"append-to-body":""},on:{"update:visible":function($event){_vm.dialogVisible=$event},"close":_vm.onDialogClose}},[_c('el-form',{ref:"form",staticClass:"multiple-ruleForm",attrs:{"model":_vm.form,"label-width":"100px","label-position":"top","size":"small","disabled":_vm.submitting}},[_c('el-row',{attrs:{"gutter":10}},_vm._l((_vm.form.items),function(item,index){return _c('el-col',{key:item.key,attrs:{"sm":24,"md":24}},[_c('el-card',{staticClass:"box-card",attrs:{"shadow":"never","body-style":{
            padding: ' 0 10px',
          }}},[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_vm._v(" 条目"+_vm._s(index + 1)+" "),_c('el-button',{staticClass:"text-danger",staticStyle:{"float":"right","padding":"3px 0"},attrs:{"type":"text","disabled":_vm.submitting || _vm.form.items.length <= 1},on:{"click":function($event){return _vm.onDeleteRow(index)}}},[_vm._v("删除")])],1),_c('el-row',{attrs:{"gutter":10}},[_c('el-col',{attrs:{"sm":24,"md":6}},[_c('el-form-item',{attrs:{"label-position":"left","label":"尺寸","prop":'items.' + index + '.lengthWidthId',"rules":[
                  {
                    required: true,
                    message: '请选择尺寸',
                    trigger: 'change',
                  },
                ]}},[_c('el-select',{staticClass:"form-select",attrs:{"placeholder":"请选择长宽"},on:{"change":function($event){return _vm.handleExistCheck(item, index)}},model:{value:(item.lengthWidthId),callback:function ($$v) {_vm.$set(item, "lengthWidthId", $$v)},expression:"item.lengthWidthId"}},_vm._l((_vm.lengthWidthsOptions),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.description,"value":item.id}})}),1)],1)],1),_c('el-col',{attrs:{"sm":24,"md":6}},[_c('el-form-item',{attrs:{"label-position":"left","label":"厚度","prop":'items.' + index + '.thicknessId',"rules":[
                  {
                    required: true,
                    message: '请选择厚度',
                    trigger: 'change',
                  },
                ]}},[_c('el-select',{staticClass:"form-select",attrs:{"placeholder":"请选择厚度"},on:{"change":function($event){return _vm.handleExistCheck(item, index)}},model:{value:(item.thicknessId),callback:function ($$v) {_vm.$set(item, "thicknessId", $$v)},expression:"item.thicknessId"}},_vm._l((_vm.thicknessesOptions),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name.i18n,"value":item.id}})}),1)],1)],1),_c('el-col',{attrs:{"sm":24,"md":6}},[_c('el-form-item',{attrs:{"label":"次序(数值越大越靠前)","prop":'items.' + index + '.seq',"rules":[
                  { required: true, message: '请输入次序', trigger: 'blur' },
                ]}},[_c('el-input',{ref:`input${index}`,refInFor:true,attrs:{"disabled":_vm.submitting,"clearable":"","placeholder":"请输入","type":"number"},model:{value:(item.seq),callback:function ($$v) {_vm.$set(item, "seq", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.seq"}})],1)],1),_c('el-col',{attrs:{"sm":24,"md":6}},[_c('el-form-item',{attrs:{"label":"是否已存在","prop":'items.' + index + '.isExists'}},[(item.isChecking)?_c('div',[_c('i',{staticClass:"el-icon-loading"}),_vm._v(" 查重中... ")]):_c('div',[(item.isExists)?_c('span',{staticClass:"text-danger"},[_vm._v("该组合已存在")]):_c('span',{staticClass:"text-success"},[_vm._v("可添加")])])])],1)],1)],1)],1)}),1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"disabled":_vm.submitting},on:{"click":_vm.handleReset}},[_vm._v("重 置")]),_c('el-button',{attrs:{"disabled":_vm.submitting},on:{"click":_vm.addRow}},[_vm._v("新增一行")]),_c('el-button',{attrs:{"type":"primary","loading":_vm.submitting},on:{"click":function($event){return _vm.submitForm('form')}}},[_vm._v(_vm._s(_vm.submitting ? "提交中..." : "提交创建"))])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }