import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["lengthWidthsOptions", "thicknessesOptions"]),
  },
  methods:{
    ...mapActions({
      refreshLengthWidthOptions: "product/refreshLengthWidthOptions",
      refreshThicknessesOptions: "product/refreshThicknessesOptions",
    }),
    initFormOptions() {
      this.refreshLengthWidthOptions();
      this.refreshThicknessesOptions({lang:'en'});
    },
  },
}