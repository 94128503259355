<template>
  <el-dialog
    title="修改规格"
    :visible.sync="dialogVisible"
    width="400px"
    :close-on-click-modal="false"
    @close="onDialogClose"
    append-to-body
  >
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-width="60px"
      class="edit-form"
      label-position="right"
      :disabled="submitting"
    >
      <el-form-item label="尺寸" prop="lengthWidthId">
        <el-select
          v-model="form.lengthWidthId"
          placeholder="请选择尺寸"
          class="form-select"
          @change="handleExistCheck"
        >
          <el-option
            v-for="item in lengthWidthsOptions"
            :key="item.id"
            :label="item.description"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="厚度" prop="thicknessId">
        <el-select
          v-model="form.thicknessId"
          placeholder="请选择厚度"
          class="form-select"
          @change="handleExistCheck"
        >
          <el-option
            v-for="item in thicknessesOptions"
            :key="item.id"
            :label="item.name.i18n"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="次序" prop="seq">
        <el-input-number
          v-model="form.seq"
          label="请输入"
          :min="1"
          style="width:100%"
        ></el-input-number>
      </el-form-item>
      <div v-if="isExists">
        <el-alert
          title="当前修改组合已存在"
          type="warning"
          center
          show-icon
          :closable="false"
        >
        </el-alert>
        <br />
      </div>
      <el-form-item style="text-align: right">
        <!-- <el-button @click="onCancel('form')">关 闭</el-button> -->
        <el-button
          type="primary"
          @click="submitForm('form')"
          :loading="submitting"
          >{{ submitting ? "保存中..." : "保存" }}</el-button
        >
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import {
  GetProductSpecificationsById,
  PutProductSpecifications,
  GetProductSpecificationsList,
} from "../api";
import { ShowApiError } from "@/request/error";
import options from "../mixins/options";
import { isEmprty } from "@/utils/validate";
export default {
  props: {
    sid: {
      type: [String, Number],
      default: null,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [options],
  data() {
    return {
      currentId: null,
      dialogVisible: false,
      loading: false,
      submitting: false,
      isExists: false,
      form: {
        lengthWidthId: null,
        thicknessId: null,
        seq: 1,
      },
      rules: {
        lengthWidthId: [
          { required: true, message: "请选择长宽", trigger: "change" },
        ],
        thicknessId: [
          { required: true, message: "请选择厚度", trigger: "change" },
        ],
        seq: [
          {
            required: true,
            message: "请输入次序",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  watch: {
    sid: {
      handler(value) {
        if (value !== null) {
          this.currentId = Number(value);
          this.getFormData();
        } else {
          this.currentId = null;
        }
      },
      immediate: true,
    },
    show: {
      handler(value) {
        this.dialogVisible = value;
        if (this.dialogVisible) {
          this.initFormOptions();
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleExistCheck() {
      return new Promise((resolve, reject) => {
        if (
          isEmprty(this.form.lengthWidthId) ||
          isEmprty(this.form.thicknessId)
        ) {
          this.isExists = false;
          resolve();
        }
        // 修改模式下，向列表查询相同参数下，是否存在和当前数据id不同的其他数据，如果存在就是重复
        // 如果查询到数据只有一条，且该条数据与当前修改的数据id一致，表示不是重复的
        GetProductSpecificationsList({
          lengthWidthId: this.form.lengthWidthId,
          thicknessId: this.form.thicknessId,
        })
          .then((res) => {
            let { content } = res.data;
            if (content.length <= 0) {
              this.isExists = false;
              resolve();
            } else {
              let results = content.filter((d) => d.id !== this.currentId);
              if (results.length > 0) {
                this.isExists = true;
                reject();
              } else {
                this.isExists = false;
                resolve();
              }
            }
          })
          .catch(() => {
            resolve();
          });
      });
    },
    submitForm(formName) {
      this.handleExistCheck().then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.submitting = true;
            PutProductSpecifications([
              {
                id: this.currentId,
                ...this.form,
              },
            ])
              .then(() => {
                this.$message.success("修改成功!");
                this.initForm();
                this.getFormData();
                this.emitSucccess();
              })
              .catch((err) => {
                ShowApiError("保存失败", err);
              })
              .finally(() => {
                this.submitting = false;
              });
          } else {
            this.$message.warning("表单有误，请检查");
            return false;
          }
        });
      });
    },
    getFormData() {
      this.loading = true;
      GetProductSpecificationsById(this.currentId)
        .then((res) => {
          let { thicknessId, lengthWidthId, seq } = res.data;
          this.form = {
            thicknessId: thicknessId,
            lengthWidthId: lengthWidthId,
            seq: seq,
          };
          this.loading = false;
        })

        .catch((err) => {
          this.loading = false;
          ShowApiError("获取表单数据失败",err );
        });
    },
    onCancel() {
      this.initForm();
      this.emitFail();
    },
    initForm() {
      this.form = {
        lengthWidthId: null,
        thicknessId: null,
        seq: 0,
      };
    },
    onDialogClose() {
      this.dialogVisible = false;
      this.isExists = false;
      this.$emit("close", this.dialogVisible);
    },
    emitSucccess() {
      this.$emit("success");
    },
    emitFail() {
      this.$emit("cancel");
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-form {
  max-width: 100%;
  padding: 0 20px;
  .form-select {
    width: 100%;
  }
}
</style>
