<template>
  <section class="product-page-header">
    <div class="left">
      <slot name="left">
        <h3 class="header-title text-primary clearfix">
          <span class="title-icon">
            <svg-icon :iconClass="$route.meta.iconSvg"></svg-icon>
          </span>
          {{ $route.meta.title }}
        </h3>
      </slot>
    </div>
    <div class="right">
      <el-button-group>
        <template v-for="tab in tabs">
          <el-button
            :type="tab.routeName == $route.name ? 'primary' : ''"
            :key="tab.routeName"
            @click="handleTab(tab)"
            size="small"
            >{{ tab.name }}</el-button
          >
        </template>
      </el-button-group>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      tabs: [
        {
          name: "产品",
          routeName: "ProductsList",
          disabled: false,
        },
        {
          name: "系列",
          routeName: "ProductSeriesList",
          disabled: false,
        },
        {
          name: "规格",
          routeName: "ProductSpecificationsList",
          disabled: false,
        },

        {
          name: "色系",
          routeName: "ProductColorsList",
          disabled: false,
        },
        {
          name: "尺寸",
          routeName: "ProductlLengthwidthList",
          disabled: false,
        },
        {
          name: "厚度",
          routeName: "ProductThicknessesList",
          disabled: false,
        },

        {
          name: "效果",
          routeName: "ProductEffectsList",
          disabled: false,
        },
        {
          name: "用途",
          routeName: "ProductUsagesList",
          disabled: false,
        },
        {
          name: "型号",
          routeName: "ProductModelsList",
          disabled: false,
        },
        {
          name: "优势",
          routeName: "ProductAdvantagesList",
          disabled: false,
        },
      ],
    };
  },
  methods: {
    handleTab(item) {
      this.$router.push({
        name: item.routeName,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.product-page-header {
  box-sizing: border-box;
  padding: 10px 15px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  .left {
    flex: 1;
    text-align: left;
    .header-title {
      font-size: 18px;
      .title-desc {
        font-size: 13px;
        color: #989898;
        text-align: left;
        padding-left: 15px;
      }
    }
  }
  .right {
    flex: 1;
    text-align: right;
  }
}
</style>
